import { Box, Stack } from '@mui/material'
import LiabilityDisclaimerImage from 'assets/documentImages/liabilityDeclaimer.png'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { DateLabel } from 'components/Label/Label'
import { DocumentTimestamp } from 'constants/document'
import { PAGE_TITLE_LIABILITY_DISCLAIMER } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import Image from 'mui-image'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const LiabilityDisclaimerPage = () => {
  const { t } = useTranslation(['document'])

  const { getDateStringByTimestampsInSeconds } = useDateTime()

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_LIABILITY_DISCLAIMER}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Stack sx={{ gap: 2, pt: 4, pb: 10, width: '40.625rem' }}>
            <H1Title
              title={t('legalDocuments.liabilityDisclaimer.title', {
                ns: 'document',
              })}
            />
            <DateLabel
              text={getDateStringByTimestampsInSeconds(
                DocumentTimestamp.LIABILITY_DISCLAIMER_ROUTE
              )}
            />
            <Image
              src={LiabilityDisclaimerImage}
              alt="liabilityDisclaimer"
              height="25rem"
              fit="cover"
            />
            <Description>
              {t('legalDocuments.liabilityDisclaimer.description', {
                ns: 'document',
              })}
            </Description>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
